<template>
  <div v-if="ipInfo && ipInfo.length <= ipDisplayLimit" style="display: flex;">
    <div v-for="(val, index) in ipInfo" v-bind:key="index" style="margin-right: 10px;">
      <span>{{ val.destIp }} : {{ val.domain }}</span>
      <span v-if="index+1 < ipInfo.length">, </span>
    </div>
  </div>
  <div v-else>
    <span
      data-html="true"
      v-b-tooltip.hover.html="destIpTooltipContent"
    >
      <b-badge pill class="text-white badge-primary" :id="getPopoverId(uniqueId)">
        ...
      </b-badge>
    </span>
  </div>
</template>

<script>
export default {
  props: {
    ipInfo: {
      type: Array,
      required: true,
    },
    uniqueId: {
      type: String,
      required: true,
    },
  },
  computed: {
    destIpTooltipContent() {
      const domainIps = this.ipInfo.map((info) => `${info.destIp} (${info.domain})`);
      return `<div>
                ${domainIps.length} 
                Destinations<hr><div style="text-align: left;">${domainIps.join('<br>')}</div>
              </div>`;
    },
  },
  data() {
    return {
      ipDisplayLimit: 2,
    };
  },
  methods: {
    getPopoverId() {
      return `ip-popover-${String(this.uniqueId)}`;
    },
  },
};
</script>
