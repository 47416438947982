export default [
  {
    title: 'Launchpad',
    route: 'launchpad',
    icon: 'GridIcon',
  },
  {
    title: 'Dashboards',
    icon: 'TrelloIcon',
    children: [
      {
        title: 'Infrastructure',
        route: 'infra',
        icon: 'CircleIcon',
      },
      {
        title: 'Applications',
        route: 'apps',
        icon: 'CircleIcon',
      },
      {
        title: 'Security',
        route: 'security',
        icon: 'CircleIcon',
      },
      {
        title: 'Security (Beta)',
        route: 'security2',
        icon: 'CircleIcon',
      },
    ],
  },
  {
    title: 'Reports',
    route: 'reports',
    icon: 'FileTextIcon',
  },
  {
    title: 'Agents',
    route: 'agents',
    icon: 'LayersIcon',
  },
  {
    title: 'Integrations',
    route: 'integrations',
    icon: 'GitPullRequestIcon',
  },
  {
    title: 'Policy Center',
    route: 'policy',
    icon: 'ServerIcon',
  },
  {
    title: 'Settings',
    route: 'settings',
    icon: 'SettingsIcon',
  },
  {
    title: 'Knowledge Base',
    route: 'knowledge base',
    icon: 'BookOpenIcon',
  },
];
