import Vue from 'vue';
import VueRouter from 'vue-router';

import AboutEnnetix from '@/xvisor/views/knowledgeBase/AboutEnnetix.vue';
import Admin from '@/xvisor/views/Admin.vue';
import Agents from '@/xvisor/views/Agents.vue';
import Application from '@/xvisor/views/Application.vue';
import Applications from '@/xvisor/views/Applications.vue';
import CompleteSignup from '@/xvisor/views/signup/CompleteSignup.vue';
import Error404 from '@/xvisor/views/error/Error404.vue';
import Faq from '@/xvisor/views/knowledgeBase/Faq.vue';
import ForgotPasswd from '@/xvisor/views/forgotPasswd/ForgotPasswd.vue';
import InfraElements from '@/xvisor/views/InfraElements.vue';
import InfraRouter from '@/xvisor/views/InfraRouter.vue';
import InfraSwitch from '@/xvisor/views/InfraSwitch.vue';
import InfraFirewall from '@/xvisor/views/InfraFirewall.vue';
import Integrations from '@/xvisor/views/Integrations.vue';
import IntroXVisor from '@/xvisor/views/knowledgeBase/IntroXVisor.vue';
import IpAttributes from '@/xvisor/views/IpAttributes.vue';
import KnowledgeBase from '@/xvisor/views/KnowledgeBase.vue';
import Launchpad from '@/xvisor/views/Launchpad.vue';
import Login from '@/xvisor/views/login/Login.vue';
import OpsStatus from '@/xvisor/views/OpsStatus.vue';
import PolicyCenter from '@/xvisor/views/PolicyCenter.vue';
import Profile from '@/xvisor/views/Profile.vue';
import Reports from '@/xvisor/views/Reports.vue';
import ResetPasswd from '@/xvisor/views/resetPasswd/ResetPasswd.vue';
import Security from '@/xvisor/views/Security.vue';
import SecurityDashboard from '@/xvisor/views/knowledgeBase/SecurityDashboard.vue';
import Integration from '@/xvisor/views/knowledgeBase/Integration.vue';
import Settings from '@/xvisor/views/Settings.vue';
import WebPortal from '@/xvisor/views/knowledgeBase/WebPortal.vue';
import XomeConfiguration from '@/xvisor/views/knowledgeBase/XomeConfiguration.vue';
import XomeInstallation from '@/xvisor/views/knowledgeBase/XomeInstallation.vue';
import Xtend from '@/xvisor/views/Xtend.vue';
import XtendGuide from '@/xvisor/views/knowledgeBase/XtendGuide.vue';
import Security2 from '@/xvisor/views/Security2.vue';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    { path: '/', name: 'launchpad', component: Launchpad },
    { path: '/main', name: 'launchpad', component: Launchpad },
    { path: '/profile', name: 'profile', component: Profile },
    { path: '/admin', name: 'admin', component: Admin },
    { path: '/agents', name: 'agents', component: Agents },
    { path: '/apps', name: 'apps', component: Applications },
    { path: '/apps/:id', name: 'app', component: Application },
    { path: '/infra', name: 'infra', component: InfraElements },
    {
      path: '/infra/router/:ip',
      name: 'infra-router',
      component: InfraRouter,
      meta: { type: 'router' },
    },
    {
      path: '/infra/firewall/:infraId',
      name: 'infra-router',
      component: InfraFirewall,
      meta: { type: 'Firewall' },
    },
    {
      path: '/infra/switch/:ip',
      name: 'infra-switch',
      component: InfraSwitch,
      meta: { type: 'switch' },
    },
    {
      path: '/xtends/:id', name: 'xtend alert tab', component: Xtend, props: { defaultAlertTab: true },
    },
    { path: '/ip/:ip', name: 'ipattrs', component: IpAttributes },
    { path: '/ops', name: 'ops', component: OpsStatus },
    { path: '/xtends/:id', name: 'xtend', component: Xtend },
    { path: '/reports', name: 'reports', component: Reports },
    { path: '/policy', name: 'policy', component: PolicyCenter },
    { path: '/security', name: 'security', component: Security },
    { path: '/security2', name: 'security2', component: Security2 },
    { path: '/settings', name: 'settings', component: Settings },
    { path: '/kb', name: 'knowledge base', component: KnowledgeBase },
    { path: '/kb/intro-xvisor', name: 'introduction', component: IntroXVisor },
    { path: '/kb/web-portal', name: 'web portal', component: WebPortal },
    { path: '/kb/xome-installation', name: 'xome installation', component: XomeInstallation },
    { path: '/kb/xome-configuration', name: 'xome configuration', component: XomeConfiguration },
    { path: '/kb/about-ennetix', name: 'about', component: AboutEnnetix },
    { path: '/kb/faq', name: 'faq', component: Faq },
    { path: '/kb/xtend-guide', name: 'xtend guide', component: XtendGuide },
    { path: '/kb/security-guide', name: 'security dashboard', component: SecurityDashboard },
    { path: '/kb/integrations-guide', name: 'integration', component: Integration },
    { path: '/integrations', name: 'integrations', component: Integrations },
    {
      path: '/login',
      name: 'login',
      component: Login,
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/forgotpasswd',
      name: 'forgotpasswd',
      component: ForgotPasswd,
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/resetpasswd/:resetPasswdId',
      name: 'resetpasswd',
      component: ResetPasswd,
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/signup/:inviteId',
      name: 'signup',
      component: CompleteSignup,
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/xtend-guide-public',
      name: 'xtend guide public',
      component: XtendGuide,
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: Error404,
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg');
  if (appLoading) {
    appLoading.style.display = 'none';
  }
});

export default router;
