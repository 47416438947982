<template>
  <div>
    <b-row class="mb-1">
      <b-col cols="6">
        <h2>{{ $t('Threat Analytics (Beta)') }}</h2>
      </b-col>
      <b-col cols="6">
        <b-row align-h="end" align-v="center">
          <TimeRangeSelector
            :minDate="minDate"
            :maxDate="maxDate"
            :initRange="timeRange"
            @change-time-range="updateStateTimeRange"
            class="mr-1"
          ></TimeRangeSelector>
        </b-row>
      </b-col>
    </b-row>
    <b-tabs>
      <b-tab title="Network Analytics" active>
        <b-row>
          <SecurityTabs :timeRange="timeRange"></SecurityTabs>
        </b-row>
      </b-tab>
      <b-tab title="Endpoint Analytics">
        <EndpointAnalyticsTabs
          :timeRange="timeRange"
        ></EndpointAnalyticsTabs>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import moment from 'moment';

import SecurityTabs from '@/xvisor/components/security/SecurityTabs2.vue';
import TimeRangeSelector from '@/xvisor/components/TimeRangeSelector.vue';
import dateCeil from '@/xvisor/utilities/dateCeil';
import EndpointAnalyticsTabs from '@/xvisor/components/security/EndpointAnalyticsTabs2.vue';

export default ({
  components: {
    EndpointAnalyticsTabs,
    SecurityTabs,
    TimeRangeSelector,
  },
  mounted() {
    this.httpGet();
  },
  data() {
    const now = dateCeil(new Date());
    return {
      maxDate: now,
      minDate: moment(now).subtract(1, 'month').toDate(),

      /** The Time range for all components on the page. */
      timeRange: this.$store.state.app.timeRange,
      processInfo: [],
      loaded: false,
    };
  },
  watch: {
    timeRange() {
      this.httpGet();
    },
  },
  methods: {
    updateStateTimeRange(timeRange) {
      this.$store.commit('app/UPDATE_TIME_RANGE', timeRange);
      this.timeRange = timeRange;
    },
    httpGet() {
      this.loaded = false;
      this.$http
        .get('/security/endpoint/processcounts.json', {
          params: {
            start: this.timeRange.start.toISOString(),
            end: this.timeRange.end.toISOString(),
          },
        })
        .then((response) => { this.processInfo = response.data; })
        .finally(() => { this.loaded = true; });
    },
  },
});
</script>
