<template>
  <div v-if="items && items.length <= displayLimit" style="display: flex;">
    <div v-for="(item, index) in items" :key="index" style="margin-right: 5px;">
      <span>{{ item }}</span>
      <span v-if="index + 1 < items.length">, </span>
    </div>
  </div>
  <div v-else>
    <span
      :id="popoverId"
      data-html="true"
      v-b-tooltip.hover.html="tooltipContent"
    >
      <b-badge pill class="text-white badge-primary">
        ...
      </b-badge>
    </span>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      required: true,
    },
    displayLimit: {
      type: Number,
      default: 3,
    },
    label: {
      type: String,
      default: null,
    },
    uniqueId: {
      type: [String, Number],
      required: true,
    },
    propName: {
      type: String,
      default: 'Items',
    },
  },
  computed: {
    tooltipContent() {
      return `${this.items.length} ${this.label || this.propName}<hr>${this.items.join('<br>')}`;
    },
    popoverId() {
      return `tooltip-${this.uniqueId}`;
    },
  },
};
</script>

<style scoped>
.badge-primary {
  cursor: pointer;
}
</style>
