<template>
  <div>
    <e-chart v-if="hasData" :options="chartOptions" autoresize/>
    <div v-else-if="loaded" class="ml-1">{{ $t("No Data Available") }}</div>
    <SpinnerCmpt v-else></SpinnerCmpt>
  </div>
</template>

<script>
import SpinnerCmpt from '@/xvisor/components/SpinnerCmpt.vue';
import colorPaletteShade from '@/xvisor/constants/colorPaletteShade';
import readableBytes from '@/xvisor/utilities/readableBytes';
import themeStyle from '@/xvisor/utilities/themeStyle';
import colorPalette from '@/xvisor/constants/colorPalette';

export default {
  props: {
    url: {
      type: String,
      required: true,
    },
    timeRange: {
      type: Object,
      required: true,
    },
  },
  components: {
    SpinnerCmpt,
  },
  data() {
    return {
      sankeyData: [],
      elementId: 'sankey-chart',
      loaded: false,
    };
  },
  watch: {
    timeRange() {
      this.httpGet();
    },
  },
  mounted() {
    this.httpGet();
  },
  computed: {
    hasData() {
      return this.loaded
        && this.sankeyData
        && (this.sankeyData.links && this.sankeyData.links.length > 0);
    },
    toggleColor() {
      return themeStyle.styleToggle(colorPalette.black, colorPalette.white);
    },
    chartOptions() {
      return {
        title: {
          text: '',
          left: 'center',
          textStyle: {
            fontSize: 30,
          },
        },
        tooltip: {
          trigger: 'item',
          triggerOn: 'mousemove',
          formatter: (info) => {
            const { data } = info;
            const nodeContent = `
              <div class="apache-echarts-tooltip">
                <div>${data.name}</div>
                <div>Total: ${readableBytes(info.value)}</div>
              </div>
            `;
            const edgeContent = `
              <div class="apache-echarts-tooltip">
                <div>${data.sourceName} → ${data.targetName}</div>
                <div>Proto: ${data.proto}</div>
                <div>${readableBytes(data.value)}</div>
              </div>
            `;
            const edgeWithVlan = data.vlanId
              ? `${edgeContent}<div class="apache-echarts-tooltip">VLAN ID: ${data.vlanId}</div>`
              : edgeContent;
            return info.dataType === 'node' ? nodeContent : edgeWithVlan;
          },
        },
        legend: {
          show: true,
        },
        series: {
          type: 'sankey',
          data: this.formatData.nodes,
          links: this.formatData.links,
          width: '100%',
          top: '0%',
          bottom: '0%',
          right: '0%',
          left: '0%',
          draggable: false,
          emphasis: {
            disable: false,
            focus: 'adjacency',
            blurScope: 'global',
          },
          labelLayout: {
            hideOverlap: true,
          },
          levels: this.getLevelOption(),
          lineStyle: {
            curveness: 0.5,
          },
        },
      };
    },
    formatData() {
      const nodes = this.sankeyData.appNodes.concat(this.sankeyData.prefixNodes).concat(this.sankeyData.ifaceNodes);
      const nodesWithTypes = [];
      const nodesMapIdToIndex = new Map();
      const nodesMapIdToName = new Map();
      const uniqueNodeNames = new Set();
      this.sankeyData.appNodes.forEach((node) => {
        nodesWithTypes.push([node.id, node.name, 'app'].join(','));
      });
      this.sankeyData.prefixNodes.forEach((node) => {
        nodesWithTypes.push([node.id, node.name, 'prefix'].join(','));
      });
      this.sankeyData.ifaceNodes.forEach((node) => {
        nodesWithTypes.push([node.id, node.name, 'iface'].join(','));
      });
      nodes.forEach((node) => {
        if (!uniqueNodeNames.has(node.name)) {
          uniqueNodeNames.add(node.name);
        }
      });
      nodesWithTypes.forEach((node, index) => {
        const [nodeIndex, nodeName, nodeType] = node.split(',');
        nodesMapIdToIndex[[nodeIndex, nodeType].join(',')] = index;
        nodesMapIdToName[[nodeIndex, nodeType].join(',')] = nodeName;
      });
      const createdLinks = this.makeLinks(nodesMapIdToIndex, nodesMapIdToName);
      const createdNodes = Array.from(uniqueNodeNames).map((nodeName) => ({ name: nodeName }));
      return {
        nodes: createdNodes,
        links: createdLinks,
      };
    },
  },
  methods: {
    makeLinks(
      nodesMapIdToIndex,
      nodesMapIdToName,
    ) {
      let allLinks = [];
      if (this.sankeyData.ifaceNodes.length !== 0) {
        const prefixToIface = this.makeSourceToDestinationLinks(
          'prefix',
          'inIface',
          nodesMapIdToIndex,
          nodesMapIdToName,
        );
        allLinks = allLinks.concat(prefixToIface);
        const infaceToOutface = this.makeSourceToDestinationLinks(
          'inIface',
          'outIface',
          nodesMapIdToIndex,
          nodesMapIdToName,
        );
        allLinks = allLinks.concat(infaceToOutface);
        const outfaceToApp = this.makeSourceToDestinationLinks(
          'outIface',
          'app',
          nodesMapIdToIndex,
          nodesMapIdToName,
        );
        allLinks = allLinks.concat(prefixToIface).concat(prefixToIface).concat(outfaceToApp);
      } else {
        const prefixToApp = this.makeSourceToDestinationLinks(
          'prefix',
          'app',
          nodesMapIdToIndex,
          nodesMapIdToName,
        );
        allLinks = allLinks.concat(prefixToApp);
      }
      return allLinks;
    },
    getLinkColorAndOpacity(amountTcp, amountUdp) {
      const percentageUdp = amountUdp / (amountUdp + amountTcp);
      const percentageTcp = 1 - percentageUdp;
      const udpToAdd = Math.floor(percentageUdp * 110);
      const opacity = 0.2 + 0.5 * percentageTcp;
      return [`#${(145 + udpToAdd).toString(16)}49ff`, opacity];
    },
    makeSourceToDestinationLinks(
      sourceType,
      destType,
      nodesMapIdToIndex,
      nodesMapIdToName,
    ) {
      const mapLinkIdToLinkInfo = this.createMapLinkIdToLinkInfo(
        sourceType,
        destType,
        nodesMapIdToIndex,
        nodesMapIdToName,
      );
      const outputLinkArray = this.createLinkArrayFromLinkMap(mapLinkIdToLinkInfo);
      return outputLinkArray;
    },
    createMapLinkIdToLinkInfo(
      sourceType,
      destType,
      nodesMapIdToIndex,
      nodesMapIdToName,
    ) {
      const mapIdToLinkInfo = new Map();
      this.sankeyData.links.forEach((link) => {
        const sourceId = this.getLinkComponentId(link, sourceType);
        const destId = this.getLinkComponentId(link, destType);
        // Ignore the links that connect the node to itself.
        // If this link is created, it will cause a cyclic graph.
        if (sourceId === destId) {
          return;
        }
        const linkInfo = {
          source: nodesMapIdToIndex[sourceId],
          target: nodesMapIdToIndex[destId],
          sourceName: nodesMapIdToName[sourceId],
          targetName: nodesMapIdToName[destId],
          value: link.bytes,
          proto: link.proto,
          vlanId: link.vlanId || undefined,
          tcpValue: 0,
          udpValue: 0,
        };
        const key = [nodesMapIdToIndex[sourceId],
          nodesMapIdToIndex[destId]].join(',');
        if (mapIdToLinkInfo.has(key)) {
          mapIdToLinkInfo.get(key).value += link.bytes;
        } else {
          mapIdToLinkInfo.set(key, linkInfo);
        }
        if (linkInfo.proto === 'UDP') {
          mapIdToLinkInfo.get(key).udpValue += linkInfo.value;
        } else {
          mapIdToLinkInfo.get(key).tcpValue += linkInfo.value;
        }
        if (mapIdToLinkInfo.get(key).udpValue > 0 && mapIdToLinkInfo.get(key).tcpValue > 0) {
          mapIdToLinkInfo.get(key).proto = `${readableBytes(mapIdToLinkInfo.get(key).udpValue)} 
            UDP & ${readableBytes(mapIdToLinkInfo.get(key).tcpValue)} TCP`;
        }
      });
      return mapIdToLinkInfo;
    },
    createLinkArrayFromLinkMap(mapLinkIdToLinkInfo) {
      const outputLinkArray = [];
      mapLinkIdToLinkInfo.forEach((val, key) => {
        const [linkColor, linkOpacity] = this.getLinkColorAndOpacity(val.tcpValue, val.udpValue);
        outputLinkArray.push({
          source: parseInt(key.split(',')[0], 10),
          target: parseInt(key.split(',')[1], 10),
          sourceName: val.sourceName,
          targetName: val.targetName,
          value: val.value,
          proto: val.proto,
          vlanId: val.vlanId,
          lineStyle: {
            color: linkColor,
            opacity: linkOpacity,
          },
        });
      });
      return outputLinkArray;
    },
    getLinkComponentId(link, componentType) {
      switch (componentType) {
        case 'prefix':
          return [link.prefixId, 'prefix'].join(',');
        case 'inIface':
          return [link.inIfaceId, 'iface'].join(',');
        case 'outIface':
          return [link.outIfaceId, 'iface'].join(',');
        default:
          return [link.appId, 'app'].join(',');
      }
    },
    getLevelOption() {
      return [
        {
          depth: 0,
          itemStyle: {
            color: colorPaletteShade.green6,
          },
          label: {
            show: true,
            color: this.toggleColor,
            position: 'left',
          },
        },
        {
          depth: 1,
          itemStyle: {
            color: colorPaletteShade.orange4,
          },
          label: {
            show: true,
            color: this.toggleColor,
            position: 'inside',
            rotate: 90,
          },
        },
        {
          depth: 2,
          itemStyle: {
            color: colorPaletteShade.blue6,
          },
          label: {
            show: true,
            color: this.toggleColor,
            position: 'inside',
            rotate: 90,
          },
        },
        {
          depth: 3,
          label: {
            show: true,
            color: this.toggleColor,
            position: 'right',
          },
        },
      ];
    },
    httpGet() {
      this.loaded = false;
      this.$http
        .get(this.url, {
          params: {
            start: this.timeRange.start.toISOString(),
            end: this.timeRange.end.toISOString(),
          },
        })
        .then((response) => { this.sankeyData = response.data; })
        .finally(() => { this.loaded = true; });
    },
  },
};
</script>

<style scoped>
.echarts {
  height: 50vh !important;
}
</style>
