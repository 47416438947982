<template>
  <div>
    <!-- TODO: Change to use generic alerts -->
    <!-- <XtendAlertsDetails
      v-if="hasAlertSelection && this.selectedAlert.type === 'Sigma'"
      :timeRange="timeRange"
      :url="xtendAlertsUrl"
    ></XtendAlertsDetails> -->
    <XtendResourceSoftwareAlertsDetail
    v-if="hasAlertSelection && (this.selectedAlert.type === 'Resource' || this.selectedAlert.type === 'Software')"
      :timeRange="timeRange"
      :url="resourceSoftwareAlertsUrl"
    ></XtendResourceSoftwareAlertsDetail>
    <h4 class="mb-1" v-else>No Alert Signature Selected</h4>
  </div>
</template>

<script>
import XomesEventBus from '@/xvisor/bus/XomesEventBus';
// TODO: Replace with generic alerts
// import XtendAlertsDetails from '@/xvisor/components/xtend/tabs/XtendAlertsDetails.vue';
import XtendResourceSoftwareAlertsDetail from '@/xvisor/components/xtend/tabs/XtendResourceSoftwareAlertsDetail.vue';

export default {
  components: {
    // TODO: Replace with generic alerts
    // XtendAlertsDetails,
    XtendResourceSoftwareAlertsDetail,
  },
  props: {
    timeRange: {
      type: Object,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      selectedAlert: null,
    };
  },
  computed: {
    hasAlertSelection() {
      return this.selectedAlert !== null && this.selectedAlert !== undefined;
    },
    // TODO: Change to use generic alerts
    // xtendAlertsUrl() {
    //   const detailEndpoint = `/${this.selectedAlert.id}/individualxtendalertdetail.json`;
    //   const fullUrl = this.url + detailEndpoint;
    //   return fullUrl;
    // },
    resourceSoftwareAlertsUrl() {
      const detailEndpoint = `/${this.selectedAlert.id}/resourcesoftwarealerts.json`;
      const fullUrl = this.url + detailEndpoint;
      return fullUrl;
    },
  },
  watch: {
    timeRange() {
      this.selectedAlert = null;
    },
  },
  created() {
    XomesEventBus.$on('update-selected-alert', (alert) => {
      this.selectedAlert = alert;
    });
  },
};
</script>

<style scoped>
  .header-card {
    width: 100%;
  }
  .header-card>.card-body {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }
  .details-row {
    gap: 5rem;
  }
</style>
