<template>
  <b-card no-body class="mx-n3">
    <b-row class="mx-0-5 my-1" align-v="center" align-h="between">
      <b-col class="users-form-selection" cols="12" md="6">
        <h5 class="mb-0">Users/Devices</h5>
        <div class="form-select">
          Show
          <b-form-select
            value="10"
            v-on:change="getSelectedPageSize"
            :options="options"
            style="width: 60px;"
            size="sm"
          ></b-form-select>
          Users
        </div>
      </b-col>
      <b-col cols="12" md="6">
        <SearchBar>
          <template #input>
            <b-form-input v-model="searchValue" type="search" placeholder="Search" />
          </template>
        </SearchBar>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <div v-if="hasData">
          <b-table
            responsive
            hover
            outlined
            primary-key="appUserId"
            :current-page="currentPage"
            :items="usersList"
            :fields="fields"
            :filter="searchValue"
            @filtered="onFiltered"
            select-mode="single"
            selectable
            @row-selected="onRowSelected"
          >
            <template #cell(ip)="row">
              <IpWrapper
                :ip="row.value"
                :timeRange="timeRange"
              >
              </IpWrapper>
            </template>
            <template #cell(name)="row">
              <IpWrapper
                :ip="row.item.ip"
                :timeRange="timeRange"
                showOnlyDeviceName
              >
              </IpWrapper>
            </template>
            <template #cell(flowBytes)="row">
              {{ readableBytes(row.value) }}
            </template>
          </b-table>
          <b-row>
            <b-col>
              <h5
                class="mb-2 mx-1 text-right"
              >
                <span
                  class="total-users-wrapper"
                  v-b-tooltip.hover
                  :title="getTotalUsersTooltip"
                >
                  Total Users: {{ usersInfo.usersCount }}
                </span>
              </h5>
            </b-col>
            <b-col>
              <ArrowPagination
                :nextPageAvailable="nextPageAvailable"
                :totalItemsCount="usersInfo.usersCount"
                :currentPageCount="usersList.length"
                :paginationParams="paginationParams"
                :sortOptions="paginationSortOptions"
              >
              </ArrowPagination>
            </b-col>
          </b-row>
        </div>
        <div v-else-if="loaded" class="ml-1-5 mb-1">
          {{ $t("No Data Available") }}
        </div>
        <SpinnerCmpt v-else class="mb-1"></SpinnerCmpt>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import axios from 'axios';

import ArrowPagination from '@/xvisor/components/ArrowPagination.vue';
import IpWrapper from '@/xvisor/components/IpWrapper.vue';
import SearchBar from '@/xvisor/components/SearchBar.vue';
import SpinnerCmpt from '@/xvisor/components/SpinnerCmpt.vue';
import UsersEventBus from '@/xvisor/bus/UsersEventBus';
import readableBytes from '@/xvisor/utilities/readableBytes';

export default {
  components: {
    ArrowPagination,
    IpWrapper,
    SearchBar,
    SpinnerCmpt,
  },
  props: {
    url: {
      type: String,
      required: true,
    },
    countUrl: {
      type: String,
      required: true,
    },
    userCountUrl: {
      type: String,
      required: true,
    },
    timeRange: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      usersInfo: {},
      usersList: [],
      currentPage: 1,
      nextPageAvailable: false,
      fields: [
        { key: 'ip', label: 'IP' },
        { key: 'name', label: 'Device Name' },
        { key: 'zoneName', label: 'Zone' },
        { key: 'flowBytes', label: 'Flow Vol', sortable: true },
        { key: 'numTrans', label: '# Trans', sortable: true },
      ],
      getNewPitUrl: '/es/pit/createid',
      options: [
        { value: 5, text: '5' },
        { value: 10, text: '10' },
        { value: 25, text: '25' },
        { value: 50, text: '50' },
      ],
      paginationParams: {
        from: 0,
        size: 10,
        searchAfter: null,
        pitId: '',
        key: 'userIp',
        desc: true,
      },
      paginationSortOptions: [
        { label: 'IP Desc.', key: 'userIp', desc: true },
        { label: 'IP Asc.', key: 'userIp', desc: false },
      ],
      searchAfterDict: {}, // used to store search after values for pagination.
      sortNumAlerts: 'sortNumAlerts',
      sortFlowVol: 'sortFlowVol',
      sortNumTrans: 'sortNumTrans',
      searchValue: '',
      filteredLength: null,
      loaded: false,
      selected: [],
    };
  },
  mounted() {
    this.getPitId();
  },
  computed: {
    getTotalUsersTooltip() {
      return 'Count Of All Unique Users';
    },
    hasData() {
      return this.loaded && this.usersList && this.usersList.length > 0;
    },
    httpParams() {
      return {
        params: {
          start: this.timeRange.start.toISOString(),
          end: this.timeRange.end.toISOString(),
          searchtext: this.searchValue && this.searchValue.length > 0 ? this.searchValue : undefined,
          size: this.paginationParams.size + 1, // Determine whether to disable next page arrow.
          pitId: this.paginationParams.pitId,
          key: this.paginationParams.key,
          desc: this.paginationParams.desc,
          searchAfter: this.searchAfterDict[this.paginationParams.from],
        },
      };
    },
    countParams() {
      return {
        params: {
          start: this.timeRange.start.toISOString(),
          end: this.timeRange.end.toISOString(),
          searchtext: this.searchValue && this.searchValue.length > 0 ? this.searchValue : undefined,
        },
      };
    },
    paginationTotalRows() {
      if (this.searchValue) return this.filteredLength;
      return this.usersList.length;
    },
  },
  watch: {
    paginationParams: {
      handler() {
        if (this.paginationParams.pitId !== '') {
          this.loaded = false;
          this.$http.get(this.url, this.httpParams)
            .then((usersListRes) => {
              const { results } = usersListRes.data;
              if (results.length > this.paginationParams.size) {
                this.nextPageAvailable = true;
                this.usersList = results.slice(0, this.paginationParams.size);
                this.searchAfterDict[this.paginationParams.from
                  + this.paginationParams.size
                ] = this.usersList[this.usersList.length - 1].ip;
              } else {
                this.nextPageAvailable = false;
                this.usersList = results;
              }
            }).catch(() => {
              this.resetPaginationParams();
              this.getPitId();
            }).finally(() => { this.loaded = true; });
        }
      },
      deep: true,
    },
    selected() {
      UsersEventBus.$emit('update-selected-user', this.selected[0]);
    },
    timeRange() {
      // Simulate reactivity of timeRange by emitting users event to expanded table row child.
      UsersEventBus.$emit('update-time-range', this.timeRange);
      this.resetPaginationParams();
      this.httpGet();
    },
    searchValue() {
      this.resetPaginationParams();
      this.httpGet();
    },
  },
  methods: {
    getPitId() {
      this.$http
        .get(this.getNewPitUrl, {
          params: {
            indexName: 'app-user-access',
            version: 8,
          },
        })
        .then((response) => {
          const { id } = response.data;
          this.paginationParams.pitId = id;
          this.httpGet();
        });
    },
    getSelectedPageSize(selected) {
      this.searchAfterDict = {};
      this.paginationParams.from = 0;
      this.paginationParams.size = selected;
    },
    httpGet() {
      this.loaded = false;
      axios
        .all([
          this.$http.get(this.url, this.httpParams),
          this.$http.get(this.countUrl, this.countParams),
        ])
        .then(axios.spread((usersListRes, usersCountRes) => {
          const { results } = usersListRes.data;
          if (results.length > this.paginationParams.size) {
            this.nextPageAvailable = true;
            this.usersList = results.slice(0, this.paginationParams.size);
            this.searchAfterDict[this.paginationParams.from
              + this.paginationParams.size
            ] = this.usersList[this.usersList.length - 1].ip;
          } else {
            this.nextPageAvailable = false;
            this.usersList = results;
          }
          this.usersInfo = usersCountRes.data;
        }))
        .finally(() => { this.loaded = true; });
    },
    onFiltered(filteredItems) {
      this.filteredLength = filteredItems.length;
      this.currentPage = 1;
    },
    onRowSelected(items) {
      this.selected = items;
    },
    resetPaginationParams() {
      this.searchAfterDict = {};
      this.paginationParams = {
        ...this.paginationParams,
        from: 0,
      };
    },
    readableBytes,
  },
};
</script>
<style>
.users-form-selection{
  display: flex;
  flex-direction: row;
  gap: 3rem;
  align-items: center;
}
</style>
