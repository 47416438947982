<template>
  <b-tabs class="w-100" lazy>
    <b-tab
      v-for="(label, key) in keyToLabel"
      :key="key"
      :active="key === 'dns'"
      :disabled="key === 'totalServers' || key === 'addresses'"
      @click="resetServerDetails()"
      lazy
    >
      <template #title>
        <div>
          <strong>{{ label }}</strong>
        </div>
      </template>
      <div v-if="validTab(key)">
      </div>
      <div v-else>
        <b-row>
          <b-tabs class="w-100 mx-2" lazy>
            <b-tab title="Suricata" active>
              <SuricataAlertsTable
                :timeRange="timeRange"
              ></SuricataAlertsTable>
            </b-tab>
            <b-tab title="Other">
            </b-tab>
          </b-tabs>
        </b-row>
      </div>
    </b-tab>
  </b-tabs>
</template>

<script>

export default {
  props: {
    timeRange: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      keyToLabel: {
        totalServers: 'Total Servers',
        dns: 'DNS',
        ssh: 'SSH',
        http: 'HTTP',
        https: 'HTTPS',
        other: 'Other',
        addresses: 'Addresses',
        alert: 'Alerts',
      },
    };
  },
  methods: {
    // TODO: This is used to prevent unused tabs from displaying any data. Remove this when other tabs are completed.
    validTab(key) {
      const validList = ['dns', 'ssh', 'http', 'https', 'other'];
      return validList.includes(key);
    },
  },
};
</script>
