<template>
  <b-tabs class="w-100">
    <b-tab title="Packages" active>
      <PackageTab
        :timeRange="timeRange"
        :url="packageSummaryUrl"
      ></PackageTab>
    </b-tab>
    <b-tab title="Processes">
      <ProcessTab
        :processInfo="processInfo"
      ></ProcessTab>
    </b-tab>
    <b-tab title="Devices">
      <DevicesTab
        :timeRange="timeRange"
        :url="devicesUrl"
      ></DevicesTab>
    </b-tab>
    <b-tab title="Users">
      <UsersTab
        :timeRange="timeRange"
        :url="usersUrl"
      ></UsersTab>
    </b-tab>
    <b-tab title="Traffic">
      <IpsTab
        :timeRange="timeRange"
        :url="ipsUrl"
        :countUrl="ipsCountUrl"
      ></IpsTab>
    </b-tab>
    <b-tab title="Alerts">
      <EndpointAlertsTab
        :timeRange="timeRange"
        :url="xtendAlertSummaryUrl"
      ></EndpointAlertsTab>
    </b-tab>
  </b-tabs>
</template>

<script>
import ProcessTab from '@/xvisor/components/security/ProcessTab.vue';
import DevicesTab from '@/xvisor/components/security/DevicesTab.vue';
import UsersTab from '@/xvisor/components/security/UsersTab.vue';
import IpsTab from '@/xvisor/components/security/IpsTab.vue';
import EndpointAlertsTab from '@/xvisor/components/security/EndpointAlertsTab.vue';
import PackageTab from '@/xvisor/components/security/PackageTab.vue';

export default {
  props: {
    timeRange: {
      type: Object,
      required: true,
    },
  },
  components: {
    DevicesTab,
    ProcessTab,
    UsersTab,
    IpsTab,
    EndpointAlertsTab,
    PackageTab,
  },
  data() {
    return {
      devicesUrl: '/security/endpoint/devicecounts.json',
      usersUrl: '/security/endpoint/usercounts.json',
      ipsUrl: '/security/endpoint/ipcounts.json',
      ipsCountUrl: '/security/endpoint/totalipcounts.json',
      xtendAlertSummaryUrl: '/security/endpoint/alertsummary.json',
      packageSummaryUrl: '/security/endpoint/packagesummary.json',
    };
  },
};
</script>
